/**
 * Please note: The browser check and update info layer was extracted to this extra entry point
 * to minimize the potential impact of errors in the other scripts.
 */

// Based on https://stackoverflow.com/questions/4900436/how-to-detect-the-installed-chrome-version
const getChromeVersion = (): number | false => {
	const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

	return raw ? parseInt(raw[2], 10) : false;
};

const getFirefoxVersion = (): number | false => {
	const raw = navigator.userAgent.match(/Firefox\/([0-9]+)\./);

	return raw ? parseInt(raw[1], 10) : false;
};

// Safari and mobile safari version
const getSafariVersion = (): number | false => {
	const raw = navigator.userAgent.match(/Version\/([0-9]+)[.0-9]*\s[Mobile/]?[\S]*[\s]?Safari\//);

	return raw ? parseInt(raw[1], 10) : false;
};

// @ts-ignore
const isIEBeforeChromium: boolean = window.document.documentMode || false; // IE / Edge <= 18 (before Chromium) https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
const chromiumVersion: number | false = getChromeVersion(); // Chrome, Edge > 18, Opera
const firefoxVersion: number | false = getFirefoxVersion();
const safariVersion: number | false = getSafariVersion();

const MIN_CHROMIUM_VERSION = 69; // Chrome Version 69 released on 2018-09-04
const MIN_FIREFOX_VERSION = 61; // Version 61 released on 2018-06-26
const MIN_SAFARI_VERSION = 11; // Version 13 released on 2017-09-19

/**
 *  Shows the browser update info for:
 * - all IE browsers before Chromium (e.g. IE11, Edge 18)
 * - all Chromium based browsers before the given minimum version (Chrome, Edge, Opera)
 * - Firefox before the given minimum version
 * - Safari before the given minimum version
 *
 * This check is a black listing. This means we explicitly define excluded browsers and show the update info only in those.
 * Older or exotic browsers won't have the info, so we don't risk showing the info too often (e.g. in new browser versions with changed user agent schema.)
 */
const showBrowserUpdateInfo: boolean =
	isIEBeforeChromium ||
	(chromiumVersion !== false && chromiumVersion < MIN_CHROMIUM_VERSION) ||
	(firefoxVersion !== false && firefoxVersion < MIN_FIREFOX_VERSION) ||
	(safariVersion !== false && safariVersion < MIN_SAFARI_VERSION);

// if (process.env.STAGE === 'local') {
// 	console.log('showBrowserUpdateInfo', showBrowserUpdateInfo);
// 	console.log('isIEBeforeChromium', isIEBeforeChromium);
// 	console.log('chromiumVersion', chromiumVersion);
// 	console.log('firefoxVersion', firefoxVersion);
// 	console.log('safariVersion', safariVersion);
// }

if (showBrowserUpdateInfo) {
	const div: HTMLElement = document.createElement('div');

	const headline: string = (window as any).HARIBO.i18n.browserUpdateInfoHeadline;
	const copy: string = (window as any).HARIBO.i18n.browserUpdateInfoCopy;

	// Please note: The HTML is based on M049 Announcement Layer and C013 Modal and needs to keep in sync
	// "z-index: 3100" is equal to priority 100 (country announcement layers have 10)
	const modalHtml: string = `
<div class="c-modal__pane c-modal__pane--default is-displayed is-visible" style="z-index: 3100;">
	<div class="c-modal__outer">
		<button id="browser-update-info-close" class="c-button c-modal__close-button c-modal__close c-button--default c-button--icon-only">
			<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" class="c-button__icon"><path d="M17.7 21.3l-.1-.1-7-6.8-6.2 6c-1 1-2.6 1-3.6.1S-.2 18 .7 17l.1-.1 6.2-6L.8 4.8c-1-1-1-2.5-.1-3.5 1-1 2.5-1 3.6-.1l.1.1 6.3 6.1L17.4.8c1-1 2.6-1 3.6-.1s1.1 2.5.1 3.5l-.1.1-6.7 6.6 7 6.8c1 1 1 2.5.1 3.5-1.1 1-2.7 1.1-3.7.1z"></path></svg>
		</button>
		<div class="c-modal__inner">
			<div class="c-modal__content-wrapper l-wrapper">
				<div class="m-announcement-layer__modal-content">
					<h3 class="fs-headline-e1">${headline}</h3>
					<div class="c-rich-text">${copy}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="c-modal__backdrop o-full">
	</div>
</div>`;

	div.innerHTML = modalHtml;

	document.body.appendChild(div);

	(document.querySelector('#browser-update-info-close') as HTMLElement).addEventListener('click', (): void => {
		if (div.parentNode) {
			div.parentNode.removeChild(div);
		}
	});
}
